import React from "react"
import styled from "@emotion/styled"
import scrollTo from 'gatsby-plugin-smoothscroll';

const Root = styled.div`
  width: calc(100% + 1.0875rem * 2);
  padding: 50px 0 50px 0;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 45px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 799px) {
    padding: 0 1.0875rem;
  }
`

const Rows = styled.ul`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  li {
    margin-bottom: 0;
    color: #3d7cd1;
    button {
      border: none;
      background: none;
      font-size: 16px;
      color: #3d7cd1;
      text-align: start;
      line-height: 32px;
    }
  }
`

const ContainerOfContents = props => {
  const title = props.title
  const paragraphs = props.paragraphs

  return (
    <Root>
      <Container>
        <h1>{title}</h1>
        <Rows>
          {paragraphs.map(paragraph => {
            const title = paragraph.title
            const englishTitle = paragraph.englishTitle
            const key = paragraph.contentful_id
            return (
              <li key={key}>
                {/* <a href={`#${englishTitle}`} key={key}>
                  {title}
                </a> */}
                <button onClick={() => scrollTo(`#${englishTitle}`)}>
                  {title}
                </button>
              </li>
            )
          })}
        </Rows>
      </Container>
    </Root>
  )
}

export default ContainerOfContents
