import React from "react"
import styled from "@emotion/styled"
import TableOfContents from "./table-of-contents"
import Paragraph from "./paragraph"

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  scroll-behavior: smooth;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const Container = styled.div`
  padding: 50px 0 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .paragraph {
    margin-bottom: 80px;
    // ページ内遷移の位置調整
    padding-top: 80px;
    margin-top: -80px;
  }
  .paragraph:first-child {
    padding-top: 50px;
    margin-top: -50px;
  }
  .paragraph:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 799px) {
    .paragraph:first-child {
      padding-top: 77px;
      margin-top: -77px;
    }
  }
`

const Paragraphs = props => {
  const title = props.title
  const unsorted = props.paragraphs
  const sorted = unsorted.sort((element1, element2) => {
    const diff = element1.displayOrder >= element2.displayOrder
    if (diff) {
      return 1
    } else {
      return -1
    }
  })

  return (
    <Root>
      <TableOfContents title={title} paragraphs={sorted} />
      <Container>
        {sorted.map(paragraph => {
          const key = paragraph.contentful_id
          return <Paragraph paragraph={paragraph} key={key} />
        })}
      </Container>
    </Root>
  )
}

export default Paragraphs
