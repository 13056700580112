import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import StrengthHeader from "../components/strength/strength-header"
import Paragraphs from "../components/strength/paragraphs"
import { UsePageContext } from "../context/page-context"
import { useState } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const Strength = ({ data }) => {
  const { setIsAtContactPage, setIsAtNotfoundPage } = UsePageContext()
  useState(
    () => {
      setIsAtContactPage(false)
      setIsAtNotfoundPage(false)
    }
  )
  
  const strengthData = data.allContentfulAquaStrength.edges[0].node
  const headerImage = strengthData.headerImage
  const headerDescription = strengthData.headerDescription
  const paragraphs = strengthData.paragraphs
  const title = strengthData.title

  const isWide = useMediaQuery("(min-width: 1079px)", { noSsr: false })

  return (
    <>
      <Seo
        title="アクアの強み"
        description="株式会社アクアの強みを紹介するページです。"
      />
      <StrengthHeader
        image={headerImage}
        description={headerDescription}
        isWide={isWide}
      />
      <Paragraphs paragraphs={paragraphs} title={title} />
    </>
  )
}

export const query = graphql`
  query StrengthQuery {
    allContentfulAquaStrength {
      edges {
        node {
          title
          headerDescription {
            childMarkdownRemark {
              html
            }
          }
          headerImage {
            gatsbyImageData(placeholder:BLURRED)
          }
          paragraphs {
            title
            englishTitle
            contentful_id
            description {
              childMarkdownRemark {
                html
              }
            }
            annotation {
              childMarkdownRemark {
                html
              }
            }
            displayOrder
          }
        }
      }
    }
  }
`

export default Strength
