import React from "react"
import styled from "@emotion/styled"
import DynamicImage from "../dynamic-image"
import { StaticImage } from "gatsby-plugin-image"

const Header = styled.div`
  margin: 0 -1.0875rem;
  display: grid;
  width: calc(100% + 2 * 1.0875rem);
  max-height: 504px;
  @media screen and (max-width: 1079px) {
    max-height: fit-content;
  }
`

const Container = styled.div`
  padding: 50px 0 34px 0;
  /* max-width: calc(372px + 50px + 497px); */
  width: 100%;
  grid-area: 1/1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    margin-right: 50px;
  }
  @media screen and (max-width: 1079px) {
    height: auto;
    flex-direction: column-reverse;
    align-items: center;
    padding: 50px 24px 34px 24px;
    .image {
      margin: 0;
    }
    .description {
      margin-bottom: 24px;
    }
  }
`

const Column = styled.div`
  width: 497px;
  display: flex;
  flex-direction: column;
  align-items: start;
  h1 {
    color: #ffffff;
    font-size: 48px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 72px;
    margin-bottom: 24px;
    @media screen and (max-width: 799px) {
      font-size: 30px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 45px;
      text-align: center;
    }
  }
  h2 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 24px;
    @media screen and (max-width: 799px) {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      br {
        display: none;
      }
    }
  }
  @media screen and (max-width: 799px) {
    width: 100%;
    align-items: center;
    /* padding: 0 24px 0 24px; */
  }
`

const ImageContainer = styled.div`
  max-width: 372px;
  display: flex;
  justify-content: center;
`

const StrengthHeader = props => {
  const image = props.image
  const descriptionHTML = props.description.childMarkdownRemark.html
  // const data = useStaticQuery(graphql`
  //   query BackgroundImageQuery {
  //     file(relativePath: { eq: "home-bg-01.png" }) {
  //       childImageSharp {
  //         fixed(height: 720) {
  //           ...GatsbyImageSharpFixed
  //         }
  //       }
  //     }
  //   }
  // `)

  const isWide = props.isWide

  const wideBackGroundImageStyle = {
    gridArea: "1/1",
    width: "100%",
    height: 504,
  }

  const narrowBackGroundImageStyle = {
    gridArea: "1/1",
    width: "100%",
    height: "auto",
  }

  return (
    <Header
    // fluid={data.file.childImageSharp.fixed}
    // style={{
    //   backgroundRepeat: 'repeat',
    //   backgroundPosition: 'center',
    //   backgroundSize: 'contain',
    // }}
    >
      {isWide && (
        <StaticImage
          src="../../images/home-bg-01.png"
          style={wideBackGroundImageStyle}
          alt=""
        />
      )}
      {!isWide && (
        <StaticImage
          src="../../images/home-bg-01-min.png"
          style={narrowBackGroundImageStyle}
          alt=""
        />
      )}
      <Container>
        <ImageContainer className="image">
          <DynamicImage image={image.gatsbyImageData} />
        </ImageContainer>
        <Column
          className="description"
          dangerouslySetInnerHTML={{ __html: descriptionHTML }}
        />
      </Container>
    </Header>
  )
}

export default StrengthHeader
