import React from "react"
import styled from "@emotion/styled"

const Description = styled.div`
  width: 100%;
  max-width: 680px;
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: justify;
    margin-bottom: 0;
  }
  img {
    margin-top: 40px;
  }
  @media screen and (max-width: 799px) {
    h1 {
      br {
        display: none;
      }
    }
  }
`

const AnnotationContainer = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  border-radius: 4px;
  background-color: #E9F7FF;
  padding: 24px;
  margin-top: 20px;
`

const Annotation = styled.div`
  width: 100%;
  max-width: 680px;
  margin-left: 12px;
  margin-bottom: 0;
  /* width: calc(100% - 24px - 12px); */
  p {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: justify;
    margin-bottom: 0;
  }
`

const Info = styled.span`
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  font-size: 12px;
  color: #ffffff;
  background-color: #182C71;
  text-align: center;
  // 調整
  margin-top: 5px;
  flex-shrink: 0;
`

const Paragraph = props => {
  const englishTitle = props.paragraph.englishTitle
  const descriptionHtml = props.paragraph.description.childMarkdownRemark.html
  const annotation = props.paragraph.annotation
  const annotationHtml = annotation?.childMarkdownRemark.html

  return (
    <div className="paragraph" id={englishTitle}>
      <Description
        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
      />
      {
        annotation && (
          <AnnotationContainer>
            <Info>i</Info>
            <Annotation dangerouslySetInnerHTML={{__html: annotationHtml}}>
            </Annotation>
          </AnnotationContainer>
        )
        
      }
    </div>
  )
}

export default Paragraph
